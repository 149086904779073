import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./TestPage.scss";
import Cookies from "universal-cookie";
import { Divider, Flex } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { MyDropdown } from "../../../Common/components/Filter";
import { BASE_URL } from "../../../config";
import { SendDialog } from "../../../Common/components/EmailDialog";
import { ExerciseDialog } from "../../../Common/components/ExerciseDialog";
import { DeleteDialog } from "../../../Common/components/DeleteDialog";

type Technology = {
  [key: number]: string;
};

type Test = {
  id: number;
  name: string;
  owner_id: number;
  difficulty: number;
  estimated_time: number;
  exercises: number[];
  technologies: Technology;
  description: string;
  creation_date: string;
  is_public: boolean;
};

type ExerciseJson = {
  id: number;
  name: string;
  description: string;
};

export default function TestPage() {
  const cookie = new Cookies(null, { path: "/" });
  const [searchTerm, setSearchTerm] = useState("");
  const [filtersList, setFiltersList] = useState<string[]>([]);
  const [showedTests, setShowedTests] = useState<Test[]>([]);
  const [testSelected, setTestSelected] = useState<Test | null>(null);
  const [jsonTests, setJsonTests] = useState<Test[]>([]);
  const [canTest, setCanTest] = useState(false);
  const [initExDone, setInitExDone] = useState(false);
  const [initDone, setInitDone] = useState(false);
  let exercisesName2: Record<number, string> = {};
  const [initTestDone, setInitTestDone] = useState(false);
  const [exoName, setExoName] = useState<Record<number, string>>({});
  const [exercises, setExercises] = useState<Record<number, ExerciseJson>>({});
  let exercises2: Record<number, ExerciseJson> = {};
  const [testOwners, setTestOwners] = useState<Record<number, string>>({});
  const [ownerName, setOwnerName] = useState("");
  const navigate = useNavigate();
  const [exerciseDialog, setExerciseDialog] = useState<ExerciseJson>({
    id: 0,
    name: "",
    description: "",
  });
  const [deleteDialog, setDeleteDialog] = useState("Voulez-vous vraiment supprimer ce test ?");

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);  
  const [errorCredit, setErrorCredit] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorDelete, setErrorDelete] = useState(false);
  const [emailToSend, setEmailToSend] = useState("");
  const [isEmailCorrect, setIsEmailCorrect] = useState(false);
  const [isSendDialogOpen, setIsSendDialogOpen] = useState(false);

  const formatDatetime = (datetimeString: string): string => {
    const datetime = new Date(datetimeString);
    const formattedTime = datetime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    const formattedDate = datetime.toLocaleDateString([], {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    const hours = formattedTime.split(":")[0];
    const minutes = formattedTime.split(":")[1];

    return `${hours}h${minutes} ${formattedDate}`;
  };

  const secondsToHHMM = (totalSeconds: number) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);

    const hoursString = hours > 0 ? hours + " h " : "";
    const minutesString = minutes > 0 ? minutes + " min" : "";

    return hoursString + minutesString;
  };

  useEffect(() => {
    document.body.classList.add("dashboard-body");
    fetchTests();
    fetchOwnerName();
  }, []);

  useEffect(() => {
    // Update showedExercises whenever jsonExercises or searchTerm changes
    filterTests();
  }, [jsonTests, searchTerm, filtersList, ownerName]);

  useEffect(() => {
    if (testSelected) {
      setCanTest(true);
    } else {
      setCanTest(false);
    }
  }, [testSelected]);

  useEffect(() => {
    if (initExDone) {
      setInitDone(true);
    }
  }, [initExDone]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchPromises = [];
        for (const test of jsonTests) {
          fetchTestOwners(test.owner_id);
        }
        setInitTestDone(true);
      } catch (error) {
        console.error("error fetching test owners");
      }
    };
    if (jsonTests.length > 0) {
      fetchData();
    }
  }, [jsonTests]);

  useEffect(() => {
    const fetchPromises = [];
    for (const test of jsonTests) {
      fetchPromises.push(fetchExercises(test.id));
      // for (const [key, element] of Object.entries(test.exercises)) {
      //   fetchPromises.push(fetchExerciseNames(element));
      // }
    }
    Promise.all(fetchPromises).then((dataArray) => {
      // on a fetch les exercices donc on peut créer la liste des noms
      const updatedExoName : Record<number, string> = {};
      for (const testId in exercises2) {
        updatedExoName[Number(testId)] = exercises2[Number(testId)].name;
      }
      setExoName(updatedExoName);
      setInitExDone(true);
      //setExoName(exercisesName2);
      setExercises(exercises2);
    });
  }, [initTestDone]);

  const fetchOwnerName = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/user/name`, {
        method: "GET", // or 'GET' depending on your backend route configuration
        headers: {
          Authorization: `Bearer ${cookie.get("access_token")}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setOwnerName(data.name);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchTests = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/test/getMyTests`, {
        method: "GET", // or 'GET' depending on your backend route configuration
        headers: {
          Authorization: `Bearer ${cookie.get("access_token")}`,
          "Content-Type": "application/json",
        },
      });
      if (response.status === 401) {
        navigate('/unauthorized');
        return;
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setJsonTests(data);
      setShowedTests(data);
      // Do something with the response data, such as updating state
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchTestOwners = async (owner_id: number) => {
    try {
      const updatedOwners = { ...testOwners, [owner_id]: ownerName };
      setTestOwners(updatedOwners);
      // Do something with the response data, such as updating state
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchExercises = async (test_id: number) => {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/exercise/${test_id}`, {
        method: "GET", // or 'GET' depending on your backend route configuration
        headers: {
          Authorization: `Bearer ${cookie.get("access_token")}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      const newExercises = data as ExerciseJson[];
      const updatedExercises: Record<number, ExerciseJson> = {};
      newExercises.forEach((exercise) => {
        updatedExercises[exercise.id] = exercise;
        exercises2 = { ...exercises2, [exercise.id]: exercise };
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchExerciseNames = async (exercise_id: number) => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/v1/exercise/name/${exercise_id}`,
        {
          method: "GET", // or 'GET' depending on your backend route configuration
          headers: {
            Authorization: `Bearer ${cookie.get("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      const newElement: ExerciseJson = {
        id: exercise_id,
        name: data.name,
        description: data.description,
      };
      exercisesName2 = { ...exercisesName2, [exercise_id]: data.name };
      // Do something with the response data, such as updating state
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const filterTests = () => {
    const filteredExercisesBySearch = jsonTests.filter((test) =>
      test.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    // If filtersList is not empty, apply the filter based on it
    let filteredExercisesByFilters = filteredExercisesBySearch;
    if (filtersList.length > 0) {
      filteredExercisesByFilters = filteredExercisesBySearch.filter((test) =>
        filtersList.every((filter) =>
          Object.values(test.technologies).some((tech) =>
            tech.toLowerCase().includes(filter.toLowerCase())
          )
        )
      );
    }
    setShowedTests(filteredExercisesByFilters); // Update showedExercises with filtered data
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterRemoveClick = (buttonId: string) => {
    const updatedFilters = filtersList.filter(
      (selectedFilters) => selectedFilters !== buttonId
    );
    setFiltersList(updatedFilters);
  };

  const handleFilterAddClick = (buttonId: string) => {
    // Check if the exercise is already in the selected exercises list
    const isAlreadySelected = filtersList.some(
      (selectedTech) => selectedTech === buttonId
    );

    if (!isAlreadySelected) {
      // Add the exercise to the selected exercises list
      setFiltersList((prevElements) => [...prevElements, buttonId]);
    }
  };

  const handleSelectTestClick = (test: Test) => {
    setTestSelected(test);
  };

  const handleTesterClick = async () => {
    try {
      if (testSelected) {
        const response = await fetch(
          `${BASE_URL}/api/v1/test/createfortest/${testSelected.id}`,
          {
            method: "GET", // or 'GET' depending on your backend route configuration
            headers: {
              Authorization: `Bearer ${cookie.get("access_token")}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        navigate(`/test/${data.test_id}`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleExercisePopup = (exerciseDialog: ExerciseJson) => {
    setExerciseDialog(exerciseDialog);
    setIsDialogOpen(true);
  };
  const handleDeleteTestModal = () => {
    setIsDeleteDialogOpen(true);
  };
  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  }
  const handleCloseModal = () => {
    setIsDialogOpen(false); // Update isOpen state to false when modal is closed
  };

  const handleSendInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(event.target.value)) {
      setIsEmailCorrect(true);
    } else {
      setIsEmailCorrect(false);
    }
    setEmailToSend(event.target.value);
  };


  const handleDeleteTest = async () => {
    try {
      if (testSelected) {
        const response = await fetch(
          `${BASE_URL}/api/v1/test/${testSelected.id}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${cookie.get("access_token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        if (!response.ok) {
          if (data.response === "not owner") {
            // Adjust the condition to match the actual response format
            setErrorDelete(true);
          } else {
            throw new Error(data.message || "Error while deleting test");
          }
        } else {
          setErrorDelete(false);
          const updatedTests = jsonTests.filter(
            (test) => test.id !== testSelected.id
          );
          setJsonTests(updatedTests);
          setTestSelected(null);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      handleCloseDeleteDialog();
    }
  };

  const handleCloseSendModal = () => {
    setIsSendDialogOpen(false);
  };

  const handleSendEmail = async () => {
    try {
      if (testSelected) {
        const requestBody = { email: emailToSend, id_test: testSelected.id };
        const response = await fetch(`${BASE_URL}/api/v1/test/send-email`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${cookie.get("access_token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          if (response.status === 432) {
            setErrorCredit(true);
          } else if (response.status === 400) {
            setErrorEmail(true);
          }
          throw new Error("Error while sending email");
        }

        const data = await response.json();
        setIsSendDialogOpen(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="flex flex-col min-h-screen xl:flex-row xl:px-36 md:px-20 p-14 justify-center items-center">
    <div className="flex flex-col w-full">
      <div className="w-form">
        <div className="container-16">
          <input
            className="text-field-4 w-input border p-2 rounded"
            maxLength={256}
            name="TestName"
            data-name="TestName"
            placeholder="Cherchez votre test"
            type="text"
            id="TestName"
            value={searchTerm}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="container-17 mt-4">
          <MyDropdown handleButtonClick={handleFilterAddClick} />
          {filtersList.map((element, index) => (
            <div className="div-block-11 mt-2" key={index} style={{ alignSelf: "start" }}>
              <div className="text-block-25">{element}</div>
              <button className="button-3 w-button ml-2" onClick={() => handleFilterRemoveClick(element)}>
                <FontAwesomeIcon icon={solid("xmark")} className="block text-sm" />
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="div-test-list scrollable mt-4 max-h-96 overflow-y-auto">
        {showedTests.length > 0 ? (
          showedTests.map((item, index) => (
            <button
              className={`test2-button flex flex-wrap ${testSelected && testSelected.id === item.id ? "selected" : ""}`}
              key={index}
              onClick={() => handleSelectTestClick(item)}
            >
              <div className="test-title text-left p-2">{item.name}</div>
              <div className="logos flex flex-wrap p-2">
                {item.is_public ? (
                  <div></div>
                ) : (
                  <div className="text2-private">Private</div>
                )}
                {Object.keys(item.technologies).map((key) => (
                  <div key={key} className={`text2-${item.technologies[parseInt(key)].toLowerCase()}`}>
                    {item.technologies[parseInt(key)]}
                  </div>
                ))}
              </div>
            </button>
          ))
        ) : (
          <div></div>
        )}
      </div>
    </div>
    <div className="separationbar2 my-10 h-1 w-full xl:h-[85vh] xl:w-3 xl:my-0 xl:mx-10 xl:p" />
    <div className="border-l border-gray-300 h-full mx-4" />
    <div className="flex flex-col w-full mt-4 xl:mt-0 xl:ml-4">
      <div className="div-block-21">
        <h3 className="heading">{testSelected ? testSelected.name : ""}</h3>
        {testSelected && (
          <button
            className="button-4 w-button mt-2"
            disabled={!canTest}
            style={{ filter: canTest ? "none" : "blur(1px)" }}
            onClick={() => handleTesterClick()}
          >
            <FontAwesomeIcon icon={solid("rocket")} className="text-sm pr-2" />
            Tester
          </button>
        )}
      </div>
      <div className="mt-4">
        <div className="flex items-center mb-2">
          <FontAwesomeIcon icon={solid("user")} className="text-lg text-white" />
          <div className="text-block-29 ml-2">Créé par :</div>
          <div className="text-block-30 ml-2">{testSelected ? testOwners[testSelected.owner_id] : "-"}</div>
        </div>
        <div className="flex items-center mb-2">
          <FontAwesomeIcon icon={solid("calendar-days")} className="text-lg text-white" />
          <div className="text-block-29 ml-2">Date de création :</div>
          <div className="text-block-30 ml-2">{testSelected && testSelected.creation_date ? formatDatetime(testSelected.creation_date) : "-"}</div>
        </div>
        <div className="flex items-center mb-2">
          <FontAwesomeIcon icon={solid("hourglass")} className="text-lg text-white" />
          <div className="text-block-29 ml-2">Durée estimée :</div>
          <div className="text-block-30 ml-2">{testSelected ? secondsToHHMM(testSelected.estimated_time) : "-"}</div>
        </div>
        <div className="flex items-center mb-2">
          <FontAwesomeIcon icon={solid("star")} className="text-lg text-white" />
          <div className="text-block-29 ml-2">Difficulté :</div>
          <div className="text-block-30 ml-2">{testSelected ? testSelected.difficulty + "/10" : "-"}</div>
        </div>
        <div className="flex items-center mb-2">
          <FontAwesomeIcon icon={solid("desktop")} className="text-lg text-white" />
          <div className="text-block-29 ml-2">Technologies : {testSelected ? Object.values(testSelected.technologies).join(", ") : "-"}</div>
        </div>
      </div>
      <div className="w-full mt-4">
        <div className="text-block-31">Description</div>
        <div className="div-block-description-test-new">
          <div className="div-block-20">
            <div className="textblocdescription">
              {testSelected ? testSelected.description : ""}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full mt-4">
        <div className="text-block-31">Exercices</div>
        <div className="div-exercises">
          <ExerciseDialog isOpen={isDialogOpen} onClose={handleCloseModal} exercise={exerciseDialog} />
          {testSelected && initDone && testSelected.exercises && exercises2 && Object.entries(testSelected.exercises).map(
            ([exerciseId, exerciseData]) => (
              <div className="div-block-20 flex items-center justify-between mb-2" key={exerciseId}>
                <div className="text-block-32">
                  Exercise {exercises[exerciseData]["name"]}
                </div>
                <button className="button-pencil" onClick={() => handleExercisePopup(exercises[exerciseData])}>
                  <FontAwesomeIcon icon={solid("arrow-up-right-from-square")} className="block text-lg text-white" />
                </button>
              </div>
            )
          )}
        </div>
      </div>
      <div className="w-full mt-4">
        <div className="text-block-31 mb-4">Email du candidat</div>
        {errorCredit && <p className="text-red-500 text-sm">Vous n'avez pas assez de crédits</p>}
        {errorEmail && <p className="text-red-500 text-sm">Une erreur est survenue lors de l'envoi de l'email. Vos crédits n'ont pas été débités.</p>}
        <div className="flex items-center">
          <input
            className="email-input border p-2 rounded w-full text-white bg-gray-700"
            maxLength={256}
            name="EmailCandidate"
            data-name="EmailCandidate"
            placeholder="candidat@email.com"
            type="email"
            id="EmailCandidate"
            value={emailToSend}
            onChange={handleSendInputChange}
            required
            style={{
              boxShadow: emailToSend !== "" ? (isEmailCorrect ? "0 0 7px rgba(30, 215, 96, 0.8)" : "0 0 7px rgba(210, 44, 44, 0.8)") : "none"
            }}
          />
          <button
            className="send-button"
            disabled={!canTest || !isEmailCorrect}
            style={{ filter: canTest && isEmailCorrect ? "none" : "blur(1px)" }}
            onClick={() => handleSendEmail()}
          >
            <div className="flex justify-center items-center">
              <FontAwesomeIcon icon={solid("paper-plane")} className="text-sm pr-2" />
              Envoyer
            </div>
          </button>
        </div>
      </div>
      <div className="flex gap-4 items-center justify-center w-full mt-4">
        <button
          className="button-6 w-button"
          disabled={!canTest}
          style={{ filter: canTest ? "none" : "blur(1px)" }}
          onClick={() => handleDeleteTestModal()}
        >
          <div className="flex justify-center items-center">
            <FontAwesomeIcon icon={solid("trash-can")} className="text-sm pr-2" />
            Supprimer
          </div>
        </button>
        <DeleteDialog 
          isOpen={isDeleteDialogOpen} 
          onDelete={handleDeleteTest} 
          onClose={handleCloseDeleteDialog}
          message={deleteDialog} 
        />
        <button
          className="button-5 w-button"
          disabled={testSelected === null}
          style={{ filter: testSelected === null ? "blur(1px)" : "none" }}
          onClick={() => navigate(`/dashboard/creationtest/${testSelected?.id}`)}
        >
          Modifier
        </button>
        <SendDialog isOpen={isSendDialogOpen} onClose={handleCloseSendModal} />
      </div>
      {errorDelete && <div className="text-red-500 mt-2">Vous ne pouvez pas supprimer un test qui ne vous appartient pas.</div>}
    </div>
  </div>
  );
}
