import React, { useState, useEffect, FormEventHandler, Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Listbox, Transition } from "@headlessui/react";
import "./Account.scss";
import { useAuth } from "../../../Auth/src/pages/Auth";
import DraggableList from "../../../Common/components/DraggableList";
import Cookies from "universal-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { BASE_URL } from "../../../config";

type Info = {
  firstname: string;
  name: string;
  email: string;
  company: string;
  credits: number;
  creation_date: string;
};


export default function Account() {
  const cookie = new Cookies(null, { path: "/" });
  const [infos, setInfos] = useState<Info>();
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add("dashboard-body");
    fetchUserInfo();
  }, []);

  const fetchUserInfo = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/user/info`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${cookie.get("access_token")}`,
          "Content-Type": "application/json",
        },
      });
      if (response.status === 401) {
        navigate('/unauthorized');
        return;
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const information = data as Info;
      setInfos(information);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEditFirstname = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/user/firstname`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${cookie.get("access_token")}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEditName = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/user/name`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${cookie.get("access_token")}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEditCompany = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/user/company`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${cookie.get("access_token")}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="flex items-center justify-center">
    <div className="flex-col xl:px-24 p-14 xl:mx-24 md:w-8/12 xl:w-6/12 justify-center items-center">
          <h4 className="text-white">Information du compte :</h4>
          <div className="div-block-58 mt-12 p-8">
            <div className="flex gap-10">
              <div className="grid-account grid-rows-6 flex-1 gap-4 xl:gap-6">
                <div className="text-slate-300 text-nowrap">Date de création :</div>
                <div className="text-slate-300">Nom :</div>
                <div className="text-slate-300">Prénom :</div>
                <div className="text-slate-300">Email :</div>
                <div className="text-slate-300">Crédits :</div>
                <div className="text-slate-300">Entreprise :</div>
              </div>
              <div className="grid-account grid-rows-6 flex-1">
                <div className="text-slate-300 text-right">
                  {infos ? infos.creation_date : "-"}
                </div>
                <div className="flex flex-wrap items-center justify-end gap-6">
                  <div className="text-slate-300">
                    {infos ? infos.name : "-"}
                  </div>
                  <button
                    className={"edit-info-button"}
                    onClick={() => handleEditName()}
                  >
                    <FontAwesomeIcon
                      icon={solid("pencil")}
                      style={{
                        display: "block",
                        fontSize: "20px",
                        color: "#1ed760",
                      }}
                    />
                  </button>
                </div>
                <div className="flex flex-wrap items-center justify-end gap-6">
                  <div className="text-slate-300 text-right">
                    {infos ? infos.firstname : "-"}
                  </div>
                  <button
                    className={"edit-info-button"}
                    onClick={() => handleEditFirstname()}
                  >
                    <FontAwesomeIcon
                      icon={solid("pencil")}
                      style={{
                        display: "block",
                        fontSize: "20px",
                        color: "#1ed760",
                      }}
                    />
                  </button>
                </div>
                <div className="flex flex-wrap items-center justify-end gap-6">
                  <div className="text-slate-300 text-right">
                    {infos ? infos.email : "-"}
                  </div>
                </div>
                <div className="flex flex-wrap items-center justify-end gap-6">
                  <div className="text-slate-300">
                    {infos ? infos.credits : "-"}
                  </div>
                  <img
                    className="add-credit-button"
                    src="https://assets-global.website-files.com/652665cf77040f9e7ef0a916/65d315a9d368d58e4d054c30_circle-plus-solid(1).svg"
                    loading="lazy"
                    width={25}
                    alt=""
                  />
                </div>
                <div className="flex flex-wrap items-center justify-end gap-6">
                  <div className="text-slate-300 text-right">
                    {infos ? infos.company : "-"}
                  </div>
                  <button
                    className={"edit-info-button"}
                    onClick={() => handleEditCompany()}
                  >
                    <FontAwesomeIcon
                      icon={solid("pencil")}
                      style={{
                        display: "block",
                        fontSize: "20px",
                        color: "#1ed760",
                      }}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        {/* <div className="w2-layout-blockcontainer container-32 w-container">
          <a href="#" className="button-15 w2-button">
            Supprimez votre compte
          </a>
        </div> */}
    </div>
    </div>
  );
}
