import { useState, useEffect, Fragment } from "react";
import { Transition, Dialog } from '@headlessui/react';
import ReactMarkdown from 'react-markdown';

type ExerciseJson = {
    id: number;
    name: string;
    description: string;
  };

interface ExerciseDialogProps {
    isOpen: boolean;
    onClose: () => void;
    exercise: ExerciseJson;
  }

export const ExerciseDialog: React.FC<ExerciseDialogProps> = ({ isOpen, onClose, exercise }) => {
    let [open, setOpen] = useState(isOpen);
  
    useEffect(() => {
      setOpen(isOpen);
    }, [isOpen]);
  
    function closeModal() {
      setOpen(false);
      onClose();
    }
  
    return (
  
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>
  
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {exercise.name}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      <ReactMarkdown className="markdown-sujet">{exercise.description.replace(/\\n/g, '\n')}</ReactMarkdown>
                    </p>
                  </div>
  
                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Ok, merci !
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    )
  }
  