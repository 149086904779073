import React, { useState, useEffect, FormEventHandler, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import DraggableList from "../../../Common/components/DraggableList";
import Cookies from "universal-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { BASE_URL } from "../../../config";
import { MyDropdown } from "../../../Common/components/Filter";
import { SuccessDialog } from "../../../Common/components/SuccessDialog";
import "./TestCreation.scss";

type Technology = {
  [key: number]: string;
};
type Exercise = {
  content: string;
  difficulty: number;
  name: string;
  id: number;
  rdm_id: number;
  estimated_time: number;
  technologies: Technology;
  is_public: boolean;
};

type Exercises = {
  [key: number]: number;
};

type Test = {
  id: number;
  owner_id: number;
  name: string;
  exercises: Exercises;
  estimated_time: number;
  creation_date: string;
  difficulty: number;
  technologies: Technology;
  description: string;
  is_public: boolean;
};

export default function TestCreation({ id }: { id: string }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [filtersList, setFiltersList] = useState<string[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [showedExercises, setShowedExercises] = useState<Exercise[]>([]);
  const [exercisesSelected, setExercisesSelected] = useState<Exercise[]>([]);
  const [jsonExercises, setJsonExercises] = useState<Exercise[]>([]);
  const [canSave, setCanSave] = useState(false);
  const cookie = new Cookies(null, { path: "/" });
  const [estimatedTime, setEstimatedTime] = useState<number>(0);
  const [testTechnologies, setTestTechnologies] = useState<Technology[]>([]);
  const [testDifficulty, setTestDifficulty] = useState<number>(0);
  const [description, setDescription] = useState("");
  const [testName, setTestName] = useState("");
  const [isTestNameAvailable, setIsTestNameAvailable] = useState(false);
  const [test, setTest] = useState<Test | null>(null);
  const [init, setInit] = useState(false);
  const navigate = useNavigate();

  const secondsToHHMM = (totalSeconds: number) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);

    const hoursString = hours > 0 ? hours + " h " : "";
    const minutesString = minutes > 0 ? minutes + " min" : "";

    return hoursString + minutesString;
  };

  useEffect(() => {
    document.body.classList.add("dashboard-body");
    fetchExercises();
  }, []);

  useEffect(() => {
    fetchTest(Number(id));
  }, [init]);

  useEffect(() => {
    //We calculate the estimated time for the test each time an exercise is added
    const newTotalTime = exercisesSelected.reduce(
      (acc, exercise) => acc + exercise.estimated_time,
      0
    );
    setEstimatedTime(newTotalTime);

    // We calculate the mean difficulty
    const newTotalDifficulty = exercisesSelected.reduce(
      (acc, exercise) => acc + exercise.difficulty,
      0
    );
    if (exercisesSelected.length === 0) {
      setTestDifficulty(0);
    } else {
      setTestDifficulty(
        Math.ceil(newTotalDifficulty / exercisesSelected.length)
      );
    }
    // We add the technologies of the exercise in the test
    const allTechnologies = exercisesSelected.reduce((techSet, exercise) => {
      Object.values(exercise.technologies).forEach((tech) => {
        techSet.add(tech);
      });
      return techSet;
    }, new Set<string>());

    // Convert Set to array and update the state with new test technologies
    setTestTechnologies(Array.from(allTechnologies));
  }, [exercisesSelected]);

  useEffect(() => {
    // Update showedExercises whenever jsonExercises or searchTerm changes
    filterExercises();
  }, [jsonExercises, searchTerm, filtersList]);

  useEffect(() => {
    checkCanSave();
  }, [exercisesSelected, isTestNameAvailable, testName]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/api/v1/test/checkTestName/${id}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${cookie.get("access_token")}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ test_name: testName }),
          }
        );
        const data = await response.json();
        if (data.response === "ok") {
          setIsTestNameAvailable(true);
        } else {
          setIsTestNameAvailable(false);
        }
      } catch (error) {
        console.error("Error fetching test name:", error);
        setIsTestNameAvailable(false);
      }
    };

    fetchData();
  }, [testName]);

  const fetchExercises = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/exercises`, {
        method: "GET", // or 'GET' depending on your backend route configuration
        headers: {
          Authorization: `Bearer ${cookie.get("access_token")}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setJsonExercises(data);
      setShowedExercises(data);
      setInit(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchTest = async (id: number) => {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/test/${id}`, {
        method: "GET", // or 'GET' depending on your backend route configuration
        headers: {
          Authorization: `Bearer ${cookie.get("access_token")}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 401) {
        navigate('/unauthorized');
        return;
      }

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data: Test = await response.json();
      setTest(data);
      setIsTestNameAvailable(true);
      setTestName(data.name);
      setDescription(data.description);
      setCanSave(true);
      if (data && data.exercises) {
        Object.entries(data.exercises).forEach(([exerciseId, exerciseData]) => {
          setJsonExercises((prevJsonExercises) =>
            prevJsonExercises.filter(
              (jsonExercise) => jsonExercise.id !== Number(exerciseData)
            )
          );
          const matchingExercise = jsonExercises.find(
            (exercise) => exercise.id === Number(exerciseData)
          );
          if (matchingExercise) {
            setExercisesSelected((prevExercisesSelected) => [
              ...prevExercisesSelected,
              matchingExercise,
            ]);
          }
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const checkCanSave = () => {
    //there is at least one exercise selected AND check if the name is present and not already taken
    if (
      exercisesSelected.length === 0 ||
      !isTestNameAvailable ||
      testName.length === 0
    ) {
      setCanSave(false);
    } else {
      setCanSave(true);
    }
  };

  const filterExercises = () => {
    const filteredExercisesBySearch = jsonExercises.filter((exercise) =>
      exercise.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    // If filtersList is not empty, apply the filter based on it
    let filteredExercisesByFilters = filteredExercisesBySearch;
    if (filtersList.length > 0) {
      filteredExercisesByFilters = filteredExercisesBySearch.filter(
        (exercise) =>
          filtersList.every((filter) =>
            Object.values(exercise.technologies).some((tech) =>
              tech.toLowerCase().includes(filter.toLowerCase())
            )
          )
      );
    }
    setShowedExercises(filteredExercisesByFilters); // Update showedExercises with filtered data
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterAddClick = (buttonId: string) => {
    // Check if the exercise is already in the selected exercises list
    const isAlreadySelected = filtersList.some(
      (selectedTech) => selectedTech === buttonId
    );

    if (!isAlreadySelected) {
      // Add the exercise to the selected exercises list
      setFiltersList((prevElements) => [...prevElements, buttonId]);
    }
  };

  const handleFilterRemoveClick = (buttonId: string) => {
    const updatedFilters = filtersList.filter(
      (selectedFilters) => selectedFilters !== buttonId
    );
    setFiltersList(updatedFilters);
  };

  const handleCloseModal = () => {
    setIsDialogOpen(false); // Update isOpen state to false when modal is closed
  };

  const handlePlusButtonClick = (exercise: Exercise) => {
    // Check if the exercise is already in the selected exercises list
    const isAlreadySelected = exercisesSelected.some(
      (selectedExercise) => selectedExercise.id === exercise.id
    );

    if (!isAlreadySelected) {
      // Add the exercise to the selected exercises list
      setExercisesSelected([...exercisesSelected, exercise]);
    }
    const updatedExercises = showedExercises.filter(
      (showedExercises) => showedExercises.id !== exercise.id
    );
    const newJsonExercises = jsonExercises.filter(
      (jsonExercise) => jsonExercise.id !== exercise.id
    );
    setJsonExercises(newJsonExercises);
    setCanSave(true);
  };
  const handleMinusButtonClick = (exercise: Exercise) => {
    const updatedExercises = exercisesSelected.filter(
      (selectedExercise) => selectedExercise.id !== exercise.id
    );
    setExercisesSelected(updatedExercises);
    setJsonExercises([...jsonExercises, exercise]);
  };

  const handleSaveButtonClick = () => {
    const exerciseIds = exercisesSelected.map((exercise) => exercise.id);
    const fetchData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/v1/test/saveTest/${id}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${cookie.get("access_token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            test_name: testName,
            estimated_time: estimatedTime,
            difficulty: testDifficulty,
            technologies: testTechnologies,
            exercises_id: exerciseIds,
            description: description,
          }),
        });
        const data = await response.json();
        if (data.response === "ok") {
          setIsTestNameAvailable(true);
          setIsDialogOpen(true);
        } else {
          setIsTestNameAvailable(false);
        }
      } catch (error) {
        console.error("Error fetching test name:", error);
        setIsTestNameAvailable(false);
      }
    };
    fetchData();
  };

  const handleTestNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTestName(event.target.value);
  };

  const handleDragEnd = (newOrder: Exercise[]) => {
    setExercisesSelected(newOrder);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(event.target.value);
  };

  return (
    <div className="flex flex-col xl:flex-row xl:px-36 md:px-20 p-14 justify-center items-center">
      <div className="flex flex-col w-full">
        <div className="w-form">
          <div className="container-16">
            <input
              className="text-field-4 w-input"
              maxLength={256}
              name="TestName"
              data-name="TestName"
              placeholder="Recherchez votre exercice"
              type="text"
              id="TestName"
              value={searchTerm}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="container-17 mt-4">
            <MyDropdown handleButtonClick={handleFilterAddClick} />
            {filtersList.map((element, index) => (
              <div
                className="div-block-11 mt-2"
                key={index}
                style={{ alignSelf: "start" }}
              >
                <div className="text-block-25">{element}</div>
                <button
                  className="button-3 w-button ml-2"
                  onClick={() => handleFilterRemoveClick(element)}
                >
                  <FontAwesomeIcon
                    icon={solid("xmark")}
                    className="block text-sm"
                  />
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="div-test-list scrollable mt-4 max-h-96 overflow-y-auto">
          <SuccessDialog isOpen={isDialogOpen} onClose={handleCloseModal} />
          {showedExercises.length > 0 ? (
            showedExercises.map((item, index) => (
              <div className="div-block-16" key={index}>
                <div className="text-block-28">{item.name}</div>
                <div className="div-block-17">
                  {item.is_public ? (
                    <div className="text3-private">Private</div>
                  ) : (
                    <></>
                  )}
                  {Object.keys(item.technologies).map((key) => (
                    <div
                      className={`text3-${item.technologies[parseInt(key)].toLowerCase()}`}
                    >
                      {item.technologies[parseInt(key)]}
                    </div>
                  ))}
                  <button
                    className="plus-exercise-button"
                    onClick={() => handlePlusButtonClick(item)}
                  >
                    <img
                      src="https://assets-global.website-files.com/652665cf77040f9e7ef0a916/65d315a9d368d58e4d054c30_circle-plus-solid(1).svg"
                      loading="lazy"
                      width={30}
                      alt=""
                    />
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div> </div>
          )}
        </div>
      </div>
      <div className="separationbar2 my-10 h-1 w-full xl:h-[85vh] xl:w-3 xl:my-0 xl:mx-10 " />
      <div className="border-l border-gray-300 h-full mx-4" />
      <div className="flex flex-col w-full mt-4 xl:mt-0 xl:ml-4 gap-4">
        <div className="div-block-21">
          <div className="form-block-3 w-form">
            <input
              className="text-field-6 w-input"
              maxLength={256}
              name="name"
              data-name="Name"
              placeholder="Nom du test"
              //value={testName}
              value={testName || ""}
              onChange={handleTestNameChange}
              type="text"
              id="name"
            />
          </div>
        </div>
        <div className="w-full mt-4">
          <div className="text-block-31">Exercices</div>
          <div className="div-exercises">
            {exercisesSelected.length > 0 && (
              <DraggableList
                items={exercisesSelected}
                onMinusButtonClick={handleMinusButtonClick}
                handleDragEnd={handleDragEnd}
              />
            )}
          </div>
        </div>
        <div className="w-full mt-4">
          <div className="text-block-31">Description</div>
          <div className="div-block-description-test-new">
            <div className="form-block-4 w-form">
              <textarea
                placeholder="Ce test a pour objectif d'évaluer les candidats sur la CI/CD de notre entreprise (Github Actions). On a également ajouté des exercices sur Terraform. Pour le dernier exercice nous testons la capacité du candidat à résoudre un problème simple de droit sur AWS."
                maxLength={5000}
                id="field"
                name="field"
                data-name="Field"
                className="textarea w-input"
                defaultValue={""}
                //value={description}
                value={description || ""}
                onChange={handleDescriptionChange}
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="flex items-center mb-2">
            <FontAwesomeIcon
              icon={solid("hourglass-half")}
              className="text-lg text-white"
            />
            <div className="text-block-29">Durée estimée : </div>
            <div className="text-block-30">
              {test?.estimated_time ? secondsToHHMM(test.estimated_time) : "-"}
            </div>
          </div>
          <div className="flex items-center mb-2">
            <FontAwesomeIcon
              icon={solid("star")}
              className="text-lg text-white"
            />
            <div className="text-block-29">Difficulté moyenne :</div>
            <div className="text-block-30">
              {test?.difficulty ? test.difficulty : "-"}
            </div>
          </div>
          <div className="flex items-center mb-2">
            <FontAwesomeIcon
              icon={solid("display")}
              className="text-lg text-white"
            />
            <div className="text-block-29">Technologies :</div>
            <div className="text-block-30">
              {test?.technologies
                ? Object.values(test.technologies).join(", ")
                : "-"}
            </div>
          </div>
        </div>
        <div className="flex gap-4 items-center justify-center w-full mt-4">
          <button
            className="button-6 w-button"
            onClick={() => setIsDialogOpen(true)}
          >
            Annuler
          </button>
          <button
            className="button-7 w-button"
            disabled={!canSave}
            style={{ filter: canSave ? "none" : "blur(1px)" }}
            onClick={() => handleSaveButtonClick()}
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  );
}
