import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { faFileInvoice, faCircleQuestion, faDisplay, faSquarePollVertical, faRightFromBracket, faUser, faCoins, faTerminal, faFlaskVial } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'universal-cookie';
import imageIcon from "../../../Common/assets/CalinfraIcon2.png";
import { BASE_URL } from "../../../config";
import "./ChaptersDashboard.scss";
import { useAuth } from "../../../Auth/src/pages/Auth";
import { Crisp } from "crisp-sdk-web";

interface SidebarButtonProps {
  title: string; // Déclarez le type de la prop "title" comme une chaîne de caractères (ou le type approprié)
  icone: IconProp;
  isActive: boolean;
  onClick: () => void;
}

function SidebarButton({ title, icone, isActive, onClick }: SidebarButtonProps) {

  const toggleBar = () => {
    onClick();
  };

  return (
    <div className={`side-chapter-item ${isActive ? 'active' : ''}`}>
        <FontAwesomeIcon icon={icone} />
        <button className={`side-chapters-button ${isActive ? 'active' : ''}`} onClick={toggleBar}>
          {title}
        </button>
    </div>
  );
}

export default function ChaptersDashboard() {
  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(true);
  const [rotate, setRotate] = useState(false);
  const [activeButton, setActiveButton] = useState(1);
  const location = useLocation();
  const cookie = new Cookies(null, {path: '/'});
  
  Crisp.configure('21d4500c-a8f2-427e-a627-1df649d6347e', {
    autoload: false
  });

  const buttonData = [
    { title: "Création de tests", icon: faFlaskVial, path: "/dashboard/creationtest/0" },
    { title: "Tests", icon: faDisplay, path: "/dashboard/vostests" },
    { title: "Résultats Candidats", icon: faSquarePollVertical, path: "/dashboard/resultatcandidats" },
    { title: "Achat crédits", icon: faCoins, path: "/dashboard/payment" },
    { title: "Compte", icon: faUser, path: "/dashboard/account" },
    { title: "Aide", icon: faCircleQuestion, path: "/dashboard/help" },
    { title: "Déconnexion", icon: faRightFromBracket, path: "/" },
    // Ajoutez d'autres boutons avec leurs titres et icônes ici
  ];

  useEffect(() => {
    initUserInfo();
    Crisp.load();
  }, []);

  function handleClick(index: number) {
    setActiveButton(index);
    navigate(buttonData[index].path);
    if (buttonData[index].title === "Déconnexion") {
      handleLogout();
    }
  }

  useEffect(() => {
    return () => {
      console.log("ChaptersDashboard component is unmounting");
      Crisp.chat.hide();
    };
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    const activeIndex = buttonData.findIndex(button => button.path === currentPath);
    if (activeIndex !== -1) {
      setActiveButton(activeIndex);
    }
  }, [location]);

  const handleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const initUserInfo = () => {
    fetch(`${BASE_URL}/api/v1/user/info`, {
      method: "GET",
      headers: {
        'Authorization': `Bearer ${cookie.get('access_token')}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status === 401) {
          navigate('/unauthorized');
          return;
        }
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        Crisp.user.setEmail(data.email);
      })
      .catch((error) => {
        console.error('There was an error fetching the user info:', error);
      });
  };

  const handleLogout = () => {
    fetch(`${BASE_URL}/api/v1/logout`, {
      method: "DELETE",
      headers: {
        'Authorization': `Bearer ${cookie.get('access_token')}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.ok) {
          setIsLoggedIn(false);
          cookie.remove("access_token");
          cookie.remove("session_id");
          navigate('/');
          //window.location.reload();
        }
        else{
          cookie.remove("access_token");
          cookie.remove("session_id");
          navigate('/');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={`sidebar-exercise ${showSidebar ? 'active' : ''} ${isLoggedIn ? 'authenticated' : ''}`}>
        <div style={{ padding: "20px" }}>
        <h1>
          <div style={{ display: "flex", alignItems: "center"}}>
            <img src={imageIcon} alt="" width={50}/>
            <a href="/" className="logo-link w-nav-brand" style={{ marginLeft: "30px", fontSize: "27px" }}>
              Calinfra
            </a>
          </div>
        </h1>
        </div>
        <nav className="side-chapters">
          
            {buttonData.map((button, index) => (
            <SidebarButton
              key={index}
              title={button.title}
              icone={button.icon}
              isActive={activeButton === index}
              onClick={() => handleClick(index)}
            />
          ))}
        </nav>
        <button className="sidebar-exercise-toggle" onClick={() => {setShowSidebar(!showSidebar); setRotate(!rotate);}}>
          <FontAwesomeIcon icon={solid("chevron-right")} style={{ fontSize: "24px", float: "right", marginRight: '7px' }} className={rotate ? "rotate active" : "rotate"} />
        </button>
      </div>

  );
}
