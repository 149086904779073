import Navbar from "../../../Common/components/Navbar";
import { useEffect, useState, useRef, FC, useCallback } from "react";
import "xterm/css/xterm.css";
import { useParams, useNavigate, Link } from "react-router-dom";
import ExerciseHeader from "../../../Common/components/ExerciseHeader";
import Button from "../../../Common/components/Button";
import ButtonNext from "../../../Common/components/ButtonNext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion, useAnimationControls } from "framer-motion";
import validation from "../../../Common/assets/validation.png";
import ReactMarkdown from "react-markdown";
import { Spin, Flex, ConfigProvider, Input } from "antd";
import { BasicDialog } from "../../../Common/components/BasicDialog";
import "./SingleExercise.scss";
import "./TestClient.scss";
import {
  solid,
  regular,
  brands,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import Cookies from "universal-cookie";
import { BASE_URL } from "../../../config";

const { TextArea } = Input;

const boxStyle: React.CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "80%",
  padding: "50px",
};
const boxStyleTerminal: React.CSSProperties = {
  width: "100%",
  padding: "50px",
};

type Technology = {
  [key: number]: string;
};

type Exercise = {
  type: number;
  id: number;
  exo_id: number;
  estimated_time: number;
  technologies: Technology;
  name: string;
  content: string;
  rdm_id: number;
  difficulty: number;
  is_public: boolean;
};

interface ProgressBarProps {
  numExercises: number; // Spécifiez le type de numExercises ici
  currentExercise: number;
}

interface IframeProps {
  url: string,
  currentExercise: number;
  testExecId: number;
  cookie: string;
  initialisation: boolean;
  cpt: React.RefObject<HTMLIFrameElement>;
  updateIframeState: (newValue: boolean) => void;
  style?: React.CSSProperties;
}

function ProgressBar({ numExercises, currentExercise }: ProgressBarProps) {
  console.log("currentExercise", currentExercise)
  return (
    <div className="progress-bar">
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <div style={{ marginRight: "20px", color: "white" }}>
          Progression:
        </div>
        <div style={{ display: "flex", gap: "5px" }}>
          {[...Array(numExercises)].map((_, index) => (
            <div
              key={index}
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: 
                index + 1 < currentExercise ? "#00ff55" :  // Green for passed exercises
                index + 1 === currentExercise ? "#007bff" :  // Blue for current exercise
                "#ccc",
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

function IframeComponent({
  url,
  currentExercise,
  testExecId,
  cookie,
  initialisation,
  cpt,
  updateIframeState,
  style,
}: IframeProps) {
  //const src = `https://${url}/api/v1/terminal/${testExecId}/${currentExercise}/`;
  const src = `https://${url}`;
  const [isCookieReady, setIsCookieReady] = useState(false);

  const handleIframeLoad = () => {
    updateIframeState(true);
  };

  useEffect(() => {
    if (cookie !== "" && initialisation) {
      // Le cookie est prêt, vous pouvez maintenant charger l'iframe
      setIsCookieReady(true);
    }
  }, [cookie, initialisation]);

  useEffect(() => {}, [currentExercise]);

  return (
    <iframe
      frameBorder="0"
      ref={cpt}
      src={src}
      onLoad={handleIframeLoad}
      width="100%"
      height="100%"
      style={style}
    />
  );
}

export default function TestClient() {
  const [urlIframe, setUrlIframe] = useState("");
  const [showIframe, setShowIframe] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [rotate, setRotate] = useState(false);
  const controls = useAnimationControls();
  const { testExecId } = useParams<{ testExecId: string }>();
  const parsedTestExecId = testExecId ? parseInt(testExecId, 10) : 0;
  const [numExercises, setNumExercises] = useState(0);
  const [currentExercise, setCurrentExercise] = useState<number>(1);
  const navigate = useNavigate();

  const [exerciseRdmId, setExerciseRdmId] = useState(0);
  const [exerciseText, setExerciseText] = useState<string>("");
  const [exerciseType, setExerciseType] = useState<number>(1);
  const [exerciseList, setExerciseList] = useState<Exercise[]>([]);
  const [cookie, setCookie] = useState("");
  const [init, setInit] = useState(false);
  const [answer, setAnswer] = useState("");
  const updatedCpt = useRef<HTMLIFrameElement>(null);
  const [timeLeft, setTimeLeft] = useState(300);
  const [showDialog, setShowDialog] = useState(false);


  useEffect(() => {
    const waitForIframeLoad = () => {
      const iframe = document.querySelector("iframe");
      setTimeout(waitForIframeLoad, 1000);
    };
    waitForIframeLoad();
  }, [testExecId, exerciseRdmId, showIframe]);

  useEffect(() => {
    if (init) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);
  
      return () => clearInterval(timer);
    }
  },[init])

  useEffect(() => {
    if (timeLeft === 180) { // 300 seconds = 5 minutes
      setShowDialog(true);
    }
  }, [timeLeft])

  useEffect(() => {
    if (timeLeft === 0) {
      const fetchTestPassedId = async () => {
        try {
          const response = await fetch(`${BASE_URL}/api/v1/test/${testExecId}/finish`, {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          if (data.test_passed_id) {
            navigate(`/score/${testExecId}/${data.test_passed_id}`);
          } else {
            console.error('test_passed_id not found in response');
          }
        } catch (error) {
          console.error('Error fetching test_passed_id:', error);
        }
      };

      fetchTestPassedId();
    }
  }, [timeLeft, testExecId, navigate]);

  const updateShowIframe = (newValue: boolean) => {
    setShowIframe(newValue);
  };

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    
    if (hours > 0) {
      return `${hours}:${minutes < 10 ? "0" : ""}${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
    } else {
      return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
    }
  };
  const fetchTimerDefaultValue = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/test/${testExecId}/timer`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 401 || response.status === 422) {
        navigate("/signin");
        return;
      }

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data.time_left) {
        setTimeLeft(Math.floor(data.time_left));
      }
    } catch (error) {
      console.error('Error fetching timer default value:', error);
    }
  };

  const fetchSessionCookie = async () => {
    const mycookie = new Cookies(null, { path: "/" });
    const session_id = mycookie.get("session_id");
    if (session_id) {
      setCookie(session_id);
      controls.start({ opacity: 0 });
    } else {
      document.cookie =
        "session_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.calinfra.fr";
      controls.start({ opacity: 0 });
      await fetch(`${BASE_URL}/getCookie`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 401 || response.status === 422) {
            // Navigate to another page if the response status is 401 Unauthorized
            navigate("/signin");
            return "";
          } else {
            return response.text();
          }
        })
        .then((text) => JSON.parse(text))
        .then((data) => {
          mycookie.set("session_id", data.session_id, {
            sameSite: "strict",
            secure: true,
            domain: ".calinfra.fr",
          });
          setCookie(data.session_id);
        })
        .catch((error) => console.log(error));
    }
  };

  const fetchStartPod = async () => {
    await fetch(
      `${BASE_URL}/api/v1/test/${testExecId}/initexercises`,
      {
        credentials: "include",
      }
    )
      .then((response) => {
        if (response.status === 401 || response.status === 422) {
          // Navigate to another page if the response status is 401 Unauthorized
          navigate("/signin");
          return "";
        } else {
          return response.text();
        }
      })
      .then((text) => JSON.parse(text))
      .then((data) => {
        setInit(true);
      })
      .catch((error) => console.error(error));
  };

  const fetchExerciseList = async () => {
    const response = await fetch(
      `${BASE_URL}/api/v1/test/${testExecId}/list`,
      {
        method: "GET", // or 'GET' depending on your backend route configuration
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const exercises = data as Exercise[];
    setExerciseRdmId(exercises[0].rdm_id);
    setExerciseList(exercises);
    setNumExercises(exercises.length);
  };

  const fetchExerciseType = async () => {
    const response = await fetch(
      `${BASE_URL}/api/v1/test/${testExecId}/type/${exerciseRdmId}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    if (data) {
      setExerciseType(data.type);
    }
  };
  const onCloseDialog = () => {
    setShowDialog(false);
  };

  const fetchExerciseContent = async () => {
    await fetch(
      `${BASE_URL}/api/v1/test/${testExecId}/${exerciseRdmId}`
    )
      .then((response) => {
        if (response.status === 401 || response.status === 422) {
          // Navigate to another page if the response status is 401 Unauthorized
          navigate("/signin");
          return "";
        } else {
          return response.json();
        }
      })
      .then((data) => {
        setExerciseText(data.content);
      })
      .catch((error) => console.error(error));
  };

  const fetchUrlIframe = async () => {
    await fetch(
      `${BASE_URL}/api/v1/test/url`,
      {
        credentials: "include",
      }
    )
      .then((response) => {
        if (response.status === 401 || response.status === 422) {
          // Navigate to another page if the response status is 401 Unauthorized
          navigate("/signin");
          return "";
        } else {
          return response.json();
        }
      })
      .then((data) => {
        setUrlIframe(data.url);
        setShowIframe(true);
      })
      .catch((error) => console.error(error));
  };

  // (0) We get the session cookie
  useEffect(() => {
    document.body.classList.add("dashboard-body");
    fetchSessionCookie();
  }, []);

  // (1) We get the url for iframe
  useEffect(() => {
    fetchUrlIframe();
  }, [cookie]);

  // (2) Get the list of exercises to get the random Ids
  useEffect(() => {
    if (cookie && testExecId) {
      fetchExerciseList();
    }
  }, [urlIframe]);

  // (3) Start Pod with the cookie and exercise Id
  useEffect(() => {
    if (cookie && testExecId && exerciseRdmId) {
      fetchStartPod();
    }
  }, [exerciseList]);

  // (4) Get the exercise type
  useEffect(() => {
    if (testExecId && exerciseRdmId) {
      fetchExerciseType();
    }
  }, [init, exerciseRdmId]);

  // (5) Get the exercise content
  useEffect(() => {
    if (testExecId && exerciseRdmId && init) {
      fetchExerciseContent();
      fetchTimerDefaultValue();
    }
  }, [exerciseType, exerciseRdmId, init]);

  // A SUPPRIMER !!! ON Ne retourne pas en arrière
  const handlePopstate = () => {
    // L'utilisateur est retourné en arrière, décrémentez le compteur ici
    setExerciseRdmId(exerciseRdmId - 1);
  };

  //On utilise cette fonction pour décrémenter lorsque le user fait un previous button
  useEffect(() => {
    // Ajoute un gestionnaire d'événement pour l'événement "popstate"
    window.addEventListener("popstate", handlePopstate);

    // Retire le gestionnaire d'événement lors du démontage du composant
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  const restartExercise = async () => {
    setShowIframe(false);
    const response = await fetch(
      `https://app.calinfra.fr/api/v1/test/restart-exercise/${testExecId}/${exerciseRdmId}`,
      {
        credentials: "include",
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    if (updatedCpt.current) {
      updatedCpt.current.src = updatedCpt.current.src;
    }
    setShowIframe(true);
  };

  // A MODIFIER => ON NE FAIT PAS +1 ON RECUP LE RDM ID DANS LA LISTE
  const NextStep = async () => {
    const incrementedParam = currentExercise; // add 1 to the integer
    setShowIframe(false);
    // Si l'exercice suivant est inférieur ou égal au nombre total d'exercices, passez à l'exercice suivant
    try {
      if (exerciseType === 1) {
        const requestBody = { answer: answer };
        const response2 = await fetch(
          `https://app.calinfra.fr/api/v1/test/${testExecId}/verification/${exerciseRdmId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );
      } else {
        const response2 = await fetch(
          `https://app.calinfra.fr/api/v1/test/${testExecId}/verification/${exerciseRdmId}`,
          {
            credentials: "include",
          }
        );
      }
      const response = await fetch(
        `https://app.calinfra.fr/api/v1/test/${testExecId}/next-exercise/${exerciseRdmId}`,
        {
          credentials: "include",
        }
      );
      let testPassedId;
      if (response.status === 200) {
        // La réponse est 200 OK
        const data = await response.json();
        if (data.hasOwnProperty("test_passed_id")) {
          testPassedId = data.test_passed_id;
          if (incrementedParam === numExercises) {
            navigate(`/score/${testExecId}/${testPassedId}`);
          }
        } else if (data.hasOwnProperty("message")) {
          if (incrementedParam < numExercises) {
            setExerciseRdmId(exerciseList[currentExercise].rdm_id);
            setCurrentExercise(currentExercise + 1);
          }
        }
      } else {
        // La réponse n'est pas 200 OK, gérez en conséquence
        console.error("La réponse n'est pas 200 OK");
      }
    } catch (error) {
      // Gérez les erreurs de requête
      console.error("Erreur lors de la requête : " + error);
    }
  };

  const onChangeResponse = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAnswer(e.target.value);
  };

  return (
    <>
      {exerciseType === 1 ? (
        <>
          {!(cookie !== "" && init) ? (
            <Flex
              style={boxStyle}
              justify="center"
              align="center"
              vertical={true}
              gap="large"
            >
              <ConfigProvider
                theme={{
                  components: {
                    Spin: {
                      dotSizeLG: 64,
                    },
                  },
                }}
              >
                <Spin size="large" />
              </ConfigProvider>
              <h5 style={{ color: "#1572f5" }}>
                Veuillez patienter quelques secondes...
              </h5>
            </Flex>
          ) : (
            <div className="code">
              <ProgressBar
                numExercises={numExercises}
                currentExercise={currentExercise}
              />
              <ExerciseHeader />
              <div className="content">
                <div className="exercise">
                  <div className="row exercise-content">
                    <div className="sujet-div-2">
                      <ReactMarkdown className="sujet">
                        {exerciseText.replace(/\\n/g, "\n")}
                      </ReactMarkdown>
                    </div>
                    <div className="vscode-2 center">
                      <TextArea
                        maxLength={100}
                        onChange={onChangeResponse}
                        placeholder="Entrez votre réponse ici."
                        style={{ height: "100%", resize: "none" }}
                      />
                    </div>
                  </div>
                  <div className="exercise-btn">
                    <button className="btn-valid" onClick={NextStep}>
                      <FontAwesomeIcon
                        icon={solid("play")}
                        style={{ marginRight: "10px" }}
                      />
                      Valider
                    </button>
                    <button className="btn-retry" onClick={restartExercise}>
                      <FontAwesomeIcon
                        icon={solid("rotate-left")}
                        style={{ marginRight: "10px" }}
                      />
                      Recommencer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {!(cookie !== "" && init) ? (
            <Flex
              style={boxStyle}
              justify="center"
              align="center"
              vertical={true}
              gap="large"
            >
              <ConfigProvider
                theme={{
                  components: {
                    Spin: {
                      dotSizeLG: 64,
                    },
                  },
                }}
              >
                <Spin size="large" />
              </ConfigProvider>
              <h5 style={{ color: "#1572f5" }}>
                Veuillez patienter quelques secondes...
              </h5>
            </Flex>
          ) : (
            <div className="flex flex-col my-10 space-y-10">
              <div className="flex justify-between px-10">
                <div className="flex items-center">
                  <ProgressBar
                    numExercises={numExercises}
                    currentExercise={currentExercise}
                  />
                  <BasicDialog
                    isOpen={showDialog}
                    title="Attention"
                    message="Il ne vous reste que 3 minutes, n'oubliez pas de valider l'exercice avant la fin du temps."
                    onClose={onCloseDialog}
                  />
                </div>
                <div className="flex items-center text-sm text-gray-400 space-x-2">
                  <FontAwesomeIcon
                    icon={solid("stopwatch")}
                    className="mr-2"
                  />
                  <div>
                    Temps restant: {formatTime(timeLeft)}
                  </div>
                </div>
              </div>
              <div className="flex" style={{ height: "75vh" }}>
                <div className="sujet-div">
                  <ReactMarkdown className="sujet2">
                    {exerciseText.replace(/\\n/g, "\n")}
                  </ReactMarkdown>
                </div>
                <div className="vscode center">
                  <IframeComponent
                    url={urlIframe}
                    currentExercise={currentExercise}
                    testExecId={parsedTestExecId}
                    cookie={cookie}
                    initialisation={init}
                    cpt={updatedCpt}
                    updateIframeState={updateShowIframe}
                    style={{
                      display: showIframe ? "block" : "none",
                    }}
                  />
                  {!showIframe && (
                    <Flex
                      style={boxStyleTerminal}
                      justify="center"
                      align="center"
                      vertical={true}
                      gap="large"
                    >
                      <ConfigProvider
                        theme={{
                          components: {
                            Spin: {
                              dotSizeLG: 64,
                            },
                          },
                        }}
                      >
                        <Spin size="large" />
                      </ConfigProvider>
                      <h5 style={{ color: "#1572f5" }}>
                        Chargement du terminal...
                      </h5>
                    </Flex>
                  )}
                </div>
              </div>
              <div className="flex flex-row-reverse px-10">
                <div className="exercise-btn2">
                  <button
                    className="btn-valid"
                    disabled={!showIframe}
                    onClick={NextStep}
                  >
                    <FontAwesomeIcon
                      icon={solid("play")}
                      style={{ marginRight: "10px" }}
                    />
                    Valider
                  </button>
                  <button className="btn-retry" onClick={restartExercise}>
                    <FontAwesomeIcon
                      icon={solid("rotate-left")}
                      style={{ marginRight: "10px" }}
                    />
                    Recommencer
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
