import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home/src/pages/Home";
import SignIn from "./Auth/src/pages/SignIn";
import SignUp from "./Auth/src/pages/SignUp";
import Unauthorized from "./Auth/src/pages/Unauthorized"
import CourseList from "./Courses/src/pages/CourseList";
import SingleExercise from "./Exercises/src/pages/SingleExercise";
import TestExercise from "./Exercises/src/pages/TestExercise";
import TestClient from "./Exercises/src/pages/TestClient";
import ChapterCompleted from "./ChapterCompleted/src/pages/ChapterCompleted";
import Pricing from "./Pricing/src/pages/Pricing";
import Principal from "./Freetry/src/pages/Principal";

import Navbar from "./Common/components/Navbar";
import NavbarNew from "./Common/components/NavbarNew";
import { AuthProvider } from "./Auth/src/pages/Auth";
import ChaptersDashboard from "./Chapters/src/pages/ChaptersDashboard";
import RouteChaptersDashboard from "./Chapters/src/pages/RouteChaptersDashboard";
import ResultFreeTest from "./Exercises/src/pages/ResultFreeTest";
import Score from "./Exercises/src/pages/Score";
import OupsyError from "./Errors/src/pages/OupsyError";
import NewHome from "./Home/src/pages/NewHome";
import AccountVerification from "./Auth/src/pages/AccountVerification";
import AccountDisable from "./Auth/src/pages/AccountDisable";
import AccountEnable from "./Auth/src/pages/AccountEnable";
import ModifyTest from "./Chapters/src/pages/ModifyTest";
import CGU from "./Home/src/pages/CGU";
import SearchDevops from "./Home/src/pages/SearchDevops";
import ForgotPassword from "./Auth/src/pages/ForgotPassword";
import ResetPassword from "./Auth/src/pages/ResetPassword";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <AuthProvider>
    <BrowserRouter>
      <Routes>
        <Route path='/'>
          <Route
            path=''
            element={
              <div>
                <NewHome />
              </div>
            }
          />
          <Route
            path='cgu'
            element={
              <div>
                <CGU />
              </div>
            }
          />
          <Route
            path='search-devops'
            element={
              <div>
                <SearchDevops />
              </div>
            }
          />
          <Route
            path='signin'
            element={
              <div>
                <Navbar />
                <SignIn />
              </div>
            }
          />
          <Route
            path='modify'
            element={
              <div>
                <Navbar />
                <ModifyTest />
              </div>
            }
          />
          <Route
            path='signup'
            element={
              <div>
                <Navbar />
                <SignUp />
              </div>
            }
          />
          <Route
            path='accountverification'
            element={
              <div>
                <Navbar />
                <AccountVerification/>
              </div>
            }
          />
          <Route
            path='accountdisable'
            element={
              <div>
                <Navbar />
                <AccountDisable/>
              </div>
            }
          />
          <Route
            path='accountenable/:verificationId'
            element={
              <div>
                <Navbar />
                <AccountEnable/>
              </div>
            }
          />
          <Route
            path='forgot-password'
            element={
              <div>
                <Navbar />
                <ForgotPassword/>
              </div>
            }
          />
          <Route
            path='reset-password/:resetId'
            element={
              <div>
                <Navbar />
                <ResetPassword/>
              </div>
            }
          />
          <Route
            path='freetry'
            element={
              <div>
                <Navbar />
                <Principal />
              </div>
            }
          />
          <Route
            path="resultfreetry/:technoName"
            element={
              <div>
                <ResultFreeTest/>
              </div>
            }
          />
          <Route
            path='dashboard/:path/:id'
            element={
              <div>
                <RouteChaptersDashboard/>
              </div>
            }
          />
          <Route
            path='dashboard/:path'
            element={
              <div>
                <RouteChaptersDashboard/>
              </div>
            }
          />
          <Route
            path='score/:testExecId/:testPassedId'
            element={
              <div>
                <Score />
              </div>
            }
          />
          <Route
            path='exerciseTest/:technoName'
            element={
              <div>
                <TestExercise />
              </div>
            }
          />
          <Route
            path='test/:testExecId'
            element={
              <div>
                <TestClient />
              </div>
            }
          />
          <Route
            path='exercise/:lessonId/:exerciseId'
            element={
              <div>
                <Navbar />
                <SingleExercise />
              </div>
            }
          />
          <Route
            path='chapterend/:chapterId'
            element={
              <div>
                <ChapterCompleted />
              </div>
            }
          />
          <Route
            path='pricing'
            element={
              <div>
                <Pricing />
              </div>
            }
          />
          <Route
            path='unauthorized'
            element={
              <div>
                <Unauthorized />
              </div>
            }
          />
          <Route
            path='error'
            element={
              <div>
                <OupsyError />
              </div>
            }
          />
        </Route>
        <Route path='courses' element={<CourseList />} />
      </Routes>
    </BrowserRouter>
  </AuthProvider>
);

reportWebVitals();
