import "./NewHome.scss";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import imageIcon from "../../../Common/assets/CalinfraIcon2.png";
import Logo from "../../../Common/components/Logo";
import Cookies from "universal-cookie";
import { Button } from "@builder.io/react";
import IconCloud from "../../../Common/components/Sphere";
import Faq from "../../../Common/components/FAQ";
import {
  Cloud,
  renderSimpleIcon,
  fetchSimpleIcons,
  SimpleIcon,
} from "react-icon-cloud";

type SimpleIconsState = {
  simpleIcons: Record<string, SimpleIcon>;
  allIcon: Record<string, { title: string; hex: string; slug: string }>; // Assuming the correct property is allIcon
};

const useIcons = (slugs: string[]) => {
  const [icons, setIcons] = React.useState<SimpleIconsState | undefined>(
    undefined
  );

  React.useEffect(() => {
    fetchSimpleIcons({ slugs }).then((data) => {
      // Explicitly define the type of data to match SimpleIconsState
      setIcons(data as SimpleIconsState);
    });
  }, [slugs]);

  if (icons) {
    return Object.values(icons.simpleIcons).map((icon) =>
      renderSimpleIcon({
        icon,
        size: 42,
        aProps: {
          onClick: (e: any) => e.preventDefault(),
        },
      })
    );
  }

  return <a>Loading</a>;
};

const slugs = [
  "amazonaws",
  "docker",
  "terraform",
  "ansible",
  "kubernetes",
  "gitlab",
];

export default function NewHome() {
  const cookie = new Cookies(null, { path: "/" });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const icons = useIcons(slugs);
  const [activeIndex, setActiveIndex] = useState(0);
  const texts = ['Devops', 'SRE'];

  useEffect(() => {
    const access_token = cookie.get("access_token");
    if (access_token && access_token !== "") {
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000); // Switch every 3 seconds

    return () => clearInterval(interval);
  }, []);

  const handleMenuButton = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const handleBuy = async () => {
    try {
      if (isLoggedIn) {
        navigate("/dashboard/billing");
      } else {
        navigate("/signin");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div>
      <style
        dangerouslySetInnerHTML={{
          __html: '.wf-force-outline-none[tabindex="-1"]:focus{outline:none;}',
        }}
      />
      <meta charSet="utf-8" />
      <title>Business - Webflow HTML website template</title>
      <meta
        content="Business - Webflow HTML website template"
        property="og:title"
      />
      <meta
        name="description"
        content="Calinfra est un outil d'évaluation pour les recruteurs Devops."
      />
      <meta
        content="https://assets-global.website-files.com/5c6eb5400253230156de2bd6/5cdc268dd7274d5c05c6009a_Business%20SEO.jpg"
        property="og:image"
      />
      <meta
        content="Business - Webflow HTML website template"
        property="twitter:title"
      />
      <meta
        content="https://assets-global.website-files.com/5c6eb5400253230156de2bd6/5cdc268dd7274d5c05c6009a_Business%20SEO.jpg"
        property="twitter:image"
      />
      <meta content="width=device-width, initial-scale=1" name="viewport" />
      <meta content="Webflow" name="generator" />
      <link
        href="https://assets-global.website-files.com/652665cf77040f9e7ef0a916/css/calinfra.webflow.d842e725b.css"
        rel="stylesheet"
        type="text/css"
      />
      <style dangerouslySetInnerHTML={{ __html: "" }} />
      <style dangerouslySetInnerHTML={{ __html: "" }} />
      <link href="https://fonts.googleapis.com" rel="preconnect" />
      <link
        href="https://fonts.gstatic.com"
        rel="preconnect"
        crossOrigin="anonymous"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Montserrat:100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic"
        media="all"
      />
      <style
        dangerouslySetInnerHTML={{
          __html:
            "@import url(https://d3e54v103j8qbb.cloudfront.net/fonts/inter/inter.s3.3a4044b2f3.css);",
        }}
      />
      <style data-emotion dangerouslySetInnerHTML={{ __html: "" }} />
      <style dangerouslySetInnerHTML={{ __html: "" }} />
      <div className="div-block">
        <div className="section cc-store-home-wrap">
          <div
            data-collapse="medium"
            data-animation="default"
            data-duration={400}
            data-easing="ease"
            data-easing2="ease"
            role="banner"
            className="navigation w-nav"
          >
            <div className="navigation-wrap">
              <div className="div-block-61">
                <Logo />
              </div>
              <div className="menu" style={{ position: "relative" }}>
                <nav role="navigation" className="navigation-items w-nav-menu">
                  <a
                    href="#technologies"
                    className="navigation-item w-nav-link"
                  >
                    Technologies
                  </a>
                  <a href="#avantages" className="navigation-item w-nav-link">
                    Avantages
                  </a>
                  <a href="#price" className="navigation-item w-nav-link">
                    Tarif
                  </a>
                  <a href="#faq" className="navigation-item w-nav-link">
                    FAQ
                  </a>
                </nav>
                <Menu
                  as="div"
                  className="show-on-small-screens relative inline-block text-left"
                >
                  <div>
                    <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-xl bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-200">
                      Menu
                      <ChevronDownIcon
                        aria-hidden="true"
                        className="-mr-1 h-5 w-5 text-gray-400"
                      />
                    </Menu.Button>
                  </div>

                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                    <div className="py-1">
                      <Menu.Item>
                        <a
                          href="#technologies"
                          className="no-underline block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                        >
                          Technologies
                        </a>
                      </Menu.Item>
                      <Menu.Item>
                        <a
                          href="#avantages"
                          className="no-underline block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                        >
                          Avantages
                        </a>
                      </Menu.Item>
                      <Menu.Item>
                        <a
                          href="#price"
                          className="no-underline block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                        >
                          Tarif
                        </a>
                      </Menu.Item>
                      <Menu.Item>
                        <a
                          href="#faq"
                          className="no-underline block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                        >
                          FAQ
                        </a>
                      </Menu.Item>
                    </div>
                    <div className="py-1">
                      <Menu.Item>
                        <a
                          href="/signin"
                          className="no-underline block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                        >
                          Se connecter
                        </a>
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Menu>
              </div>
              <div className={"hide-on-small-screens"}>
                {!isLoggedIn ? (
                  <button
                    className={"button cc-contact-us w-inline-block"}
                    onClick={() => navigate("signin")}
                  >
                    Se connecter
                  </button>
                ) : (
                  <button
                    className={"button cc-contact-us w-inline-block"}
                    onClick={() => navigate("dashboard/vostests")}
                  >
                    Dashboard
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="intro-header">
            <div className="intro-content cc-homepage">
              <div className="intro-text">
                <section className="section-2">
                  <div className="heading-jumbohome">
                    <span className="mr-2">Outil de recrutement</span>
                    <span className="text-span">
                      <div className="text-switcher">
                        {texts.map((text, index) => (
                          <div
                            key={text}
                            className={`text-switch-item ${
                              index === activeIndex ? "active" : ""
                            }`}
                          >
                            {text}
                          </div>
                        ))}
                      </div>
                    </span>
                  </div>
                </section>
                <div className="paragraph-biggerhome cc-bigger-white-lighthome">
                  Ne perdez plus de temps à évaluer les compétences techniques
                  de vos candidats Devops. Notre outil s'en charge pour vous.
                  <br />
                </div>
              </div>
              <button
                className={
                  "button cc-jumbo-button cc-jumbo-white w-inline-block text-block-22"
                }
                onClick={() => navigate("signup")}
              >
                S'INSCRIRE
              </button>
            </div>
          </div>
          <div className="w-layout-blockcontainer container-3home w-container">
            <img
              src="https://assets-global.website-files.com/652665cf77040f9e7ef0a916/661509c838d26642fcad841b_DashboardTest.png"
              loading="lazy"
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 92vw, (max-width: 991px) 688px, 940px"
              srcSet="https://assets-global.website-files.com/652665cf77040f9e7ef0a916/661509c838d26642fcad841b_DashboardTest-p-500.png 500w, https://assets-global.website-files.com/652665cf77040f9e7ef0a916/661509c838d26642fcad841b_DashboardTest-p-800.png 800w, https://assets-global.website-files.com/652665cf77040f9e7ef0a916/661509c838d26642fcad841b_DashboardTest-p-1080.png 1080w, https://assets-global.website-files.com/652665cf77040f9e7ef0a916/661509c838d26642fcad841b_DashboardTest.png 1577w"
              alt=""
              className="image-2home"
            />
          </div>
          <div className="containerhome">
            <div className="home-content-wraphome">
              <div className="text-block-2home">
                Concevez vos{" "}
                <span className="text-span-7home">examens techniques</span> ...{" "}
                <br />
                en quelques minutes
              </div>
              <div
                id="w-node-_5b5434d5-cef6-6086-682c-f9562bf66c85-7ef0a961"
                className="w-layout-layout quick-stack-12home wf-layout-layout"
              >
                <div
                  id="w-node-_5b5434d5-cef6-6086-682c-f9562bf66c86-7ef0a961"
                  className="w-layout-cell cell-18home"
                >
                  <div
                    style={{ paddingTop: "75%" }}
                    className="video w-video w-embed"
                  >
                    <iframe
                      className="embedly-embed"
                      src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.loom.com%2Fembed%2F27cd91eaf2d749e8b0dbc38e0062294c&display_name=Loom&url=https%3A%2F%2Fwww.loom.com%2Fshare%2F27cd91eaf2d749e8b0dbc38e0062294c%3Fsid%3Dae2edbc1-3136-4b1a-bbed-42685ff37ea3&image=https%3A%2F%2Fcdn.loom.com%2Fsessions%2Fthumbnails%2F27cd91eaf2d749e8b0dbc38e0062294c-1712682023431.gif&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=loom"
                      width={940}
                      height={705}
                      scrolling="no"
                      allowFullScreen
                      title="Calinfra - 9 April 2024"
                    />
                  </div>
                </div>
                <div
                  id="w-node-_5b5434d5-cef6-6086-682c-f9562bf66c87-7ef0a961"
                  className="w-layout-cell cell-19home"
                >
                  <div className="w-layout-blockcontainer w-container">
                    <div className="text-block-20home">
                      Notre plateforme redonne le contrôle aux professionnels
                      des ressources humaines lors des entretiens techniques
                      DevOps.
                      <br />
                      <br />
                      <span className="text-span-2">Personnalisable</span> :
                      explorez notre base de données et assemblez des exercices
                      pour créer un examen unique. Communiquez-nous vos besoins
                      par message, et nous élaborerons un examen sur-mesure pour
                      vous !<br />
                      <br />
                      <span className="text-span-3">Réaliste</span> : les
                      examens peuvent prendre la forme de projets à réaliser sur
                      plusieurs heures, offrant ainsi une évaluation plus
                      approfondie du candidat dans des conditions de travail
                      authentiques
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-layout-blockcontainer container-2home w-container">
          <div className="text-block-6home">
            Un <span className="text-span-4">environnement technique </span>
            <br />
            créé pour l'examen
          </div>
          <div
            id="w-node-d4600e5b-c673-1429-43eb-9495e89fcc80-7ef0a961"
            className="w-layout-layout quick-stack-6home wf-layout-layout"
          >
            <div
              id="w-node-d4600e5b-c673-1429-43eb-9495e89fcc81-7ef0a961"
              className="w-layout-cell cell-14home"
            >
              <img
                src="https://assets-global.website-files.com/652665cf77040f9e7ef0a916/658efee520697f6f9d834b2e_vscode-calinfra.png"
                loading="lazy"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 66vw, (max-width: 991px) 74vw, 50vw"
                srcSet="https://assets-global.website-files.com/652665cf77040f9e7ef0a916/658efee520697f6f9d834b2e_vscode-calinfra-p-500.png 500w, https://assets-global.website-files.com/652665cf77040f9e7ef0a916/658efee520697f6f9d834b2e_vscode-calinfra.png 883w"
                alt="Vs Code Terminal"
                className="image-8home"
              />
            </div>
            <div
              id="w-node-d4600e5b-c673-1429-43eb-9495e89fcc82-7ef0a961"
              className="w-layout-cell cell-15home"
            >
              <div className="w-layout-blockcontainer container-7home w-container">
                <div className="w-layout-blockcontainer container-9home w-container">
                  <img
                    src="https://assets-global.website-files.com/652665cf77040f9e7ef0a916/658f0144573b9b2d743ce94a_computer-solid.svg"
                    loading="lazy"
                    width={45}
                    alt="Computer icon"
                    className="image-2home2"
                  />
                </div>
                <div className="w-layout-blockcontainer container-8home w-container">
                  <div className="text-block-8home">
                    Infrastructure sur-mesure
                  </div>
                  <p className="paragraphhome">
                    Pour chaque exercice, une infrastructure est déployée afin
                    de reproduire une situation réelle.
                  </p>
                </div>
              </div>
              <div className="w-layout-blockcontainer container-10home w-container">
                <div className="w-layout-blockcontainer container-11home w-container">
                  <img
                    src="https://assets-global.website-files.com/652665cf77040f9e7ef0a916/658f0150ff63da989b89a0cc_code-solid.svg"
                    loading="lazy"
                    width={45}
                    alt="Code icon"
                    className="image-3home"
                  />
                </div>
                <div className="w-layout-blockcontainer w-container">
                  <div className="text-block-9home">VS Code</div>
                  <p className="paragraph-2">
                    Un éditeur de code ainsi qu'un terminal sont à la
                    disposition du candidat pour mettre en pratique ses
                    compétences DevOps et résoudre l'exercice.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-layout-blockcontainer container-3home2 w-container">
          <div className="text-block-10home">
            <span className="text-span-6">Un compte rendu détaillé</span> et
            <br />
            simple à comprendre
          </div>
          <div
            id="w-node-_9c24cfeb-d356-afd5-e69f-bbdc738da0c7-7ef0a961"
            className="w-layout-layout quick-stack-11home wf-layout-layout"
          >
            <div
              id="w-node-_9c24cfeb-d356-afd5-e69f-bbdc738da0c8-7ef0a961"
              className="w-layout-cell cell-23home"
            >
              <div className="w-layout-blockcontainer container-12home w-container">
                <div className="w-layout-blockcontainer container-14home w-container">
                  <div className="text-block-11home">Orienté non technique</div>
                  <p className="paragraph-3">
                    Le compte rendu se veut simple à comprendre, afin que les
                    personnes non techniques en charge du recrutement puissent
                    se faire une idée claire du niveau du candidat.
                  </p>
                </div>
              </div>
            </div>
            <div
              id="w-node-_9c24cfeb-d356-afd5-e69f-bbdc738da0c9-7ef0a961"
              className="w-layout-cell cell-22home"
            >
              <img
                src="https://assets-global.website-files.com/652665cf77040f9e7ef0a916/65b3b53aa2a928e64d375bac_Compte%20rendu%202(1).svg"
                loading="lazy"
                alt="Resume"
                className="image-4home"
              />
            </div>
          </div>
        </div>
        <div className="w-layout-blockcontainer container-33home w-container">
          <div>
            <div className="text-block-89home">
              <span className="text-span-5">Un outil</span> fait pour :
            </div>
            <div className="text-block-90home">
              Toutes les entreprises et individus,
              <span className="text-span-8">
                {" "}
                avec ou sans expertise Devops
              </span>
              , <br />
              souhaitant évaluer le niveau d'un candidat Devops :<br /> <br />
              Agences de recrutement, PME, Startup, Grands groupes
            </div>
          </div>
        </div>
        <div className="w-layout-blockcontainer container-4home w-container">
          <div
            id="technologies"
            className="flex justify-center xl:justify-end gap-4 mx-4 xl:mx-48 font-bold"
          >
            <div className="text-white text-xl sm:text-4xl justify-center text-center">
              Des exercices sur les technologies{" "}
              <span className="text-green-500">devops</span>
            </div>
          </div>
          <div className="flex flex-col xl:flex-row w-full items-center justify-center">
            <div className="flex flex-col xl:flex-row w-10/12 md:w-8/12 items-center justify-center gap-32">
              <IconCloud iconSlugs={slugs} />
              <div className="text-gray-300 leading-relaxed text-base text-center xl:text-left sm:text-xl xl:w-5/12">
                Les exercices traitent de technologies orientées
                devops/infrastructure. Nous comptons actuellement des exercices
                sur les technologies suivantes: Kubernetes; Docker; Ansible;
                Terraform pour AWS; Linux. D'autres exercices sont à venir sur:
                GCP, Azure, Gitlab et autres.
              </div>
            </div>
          </div>
        </div>
        <section id="avantages" className="section-5home">
          <div>
            <div className="containerhome2">
              <div className="text-block-14home">
                Ce que vous <span className="text-span-11">gagnez !</span>
              </div>
              <div
                id="w-node-_4fdd2705-5a09-5209-0963-5efe3709fa3b-7ef0a961"
                className="w-layout-layout quick-stack-3home wf-layout-layout"
              >
                <div
                  id="w-node-_61f40e6a-6269-8937-8d25-888eb97f2461-7ef0a961"
                  className="w-layout-cell cell-8home"
                >
                  <div className="div-block-8home transform transition-transform duration-300 hover:scale-105">
                    <div className="div-block-4home">
                      <img
                        src="https://assets-global.website-files.com/652665cf77040f9e7ef0a916/6593036ad854e03b88b16056_money-bill-wave-solid.svg"
                        loading="lazy"
                        width={55}
                        alt=""
                        className="image-6"
                      />
                    </div>
                    <div className="text-block-15home">
                      Coût d'un recrutement tech râté
                    </div>
                    <div className="text-block-16home">
                      30 000€
                      <br />-<br />
                      150 000€
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_4243844e-447a-5e4b-fd5d-13993081d60a-7ef0a961"
                  className="w-layout-cell cell-9home"
                >
                  <div className="div-block-6home transform transition-transform duration-300 hover:scale-105">
                    <div className="div-block-5home">
                      <img
                        src="https://assets-global.website-files.com/652665cf77040f9e7ef0a916/65930739d854e03b88b3aa27_list-check-solid.svg"
                        loading="lazy"
                        width={43}
                        alt=""
                        className="image-6"
                      />
                    </div>
                    <div className="text-block-15home2">
                      Evaluation des compétences
                    </div>
                    <div className="text-block-16home2">
                      Concrète,
                      <br />
                      Optimisée pour vos
                      <br />
                      problématiques,
                      <br />
                      Sans effort
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_224b0701-3b32-8f02-0573-5a4afef59d0b-7ef0a961"
                  className="w-layout-cell cell-10home"
                >
                  <div className="div-block-8home2 transform transition-transform duration-300 hover:scale-105">
                    <div className="div-block-7home">
                      <img
                        src="https://assets-global.website-files.com/652665cf77040f9e7ef0a916/659305c296a96e82e2bbedcd_hourglass-half-regular.svg"
                        loading="lazy"
                        width={35}
                        alt=""
                        className="image-6"
                      />
                    </div>
                    <div className="text-block-15home3">
                      Libérez du temps aux équipes techniques
                    </div>
                    <div className="text-block-16home3">2 heures/entretien</div>
                    <div className="text-block-17home3">
                      (préparation + entretien)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          id="price"
          className="w-layout-blockcontainer container-34home w-container"
        >
          <div className="div-block-62home">
            <div className="text-block-91home">
              Assurez-vous que chaque
              <br />
              candidat{" "}
              <span className="text-span-9">convient à vos attentes</span>{" "}
              <br />
              techniques
            </div>
            <div className="text-block-92home">
              Choisissez entre l'abonnement mensuel et le paiement unique pour
              débloquer des crédits et commencer à évaluer vos candidats sans
              plus attendre.
            </div>
            <a href="#" className="button-17 w-button">
              Mensuel
            </a>
            <div className="div-block-63home">
              <h1 className="heading-3home">Occasionnel</h1>
              <p className="paragraph-8home">
                Pour les petites entreprises ou <br />
                recruteurs occasionnels. Sans
                <br />
                abonnement
              </p>
              <div className="text-block-93home">
                <span className="text-span-10">12€</span>/test
              </div>
              <div className="div-block-64home">
                <div className="div-block-65home2">
                  <img
                    src="https://assets-global.website-files.com/652665cf77040f9e7ef0a916/65d22e0bf7d3a9b675c035ad_check-solid(1).svg"
                    loading="lazy"
                    width={20}
                    alt=""
                  />
                  <div className="text-block-94home">
                    {" "}
                    Créer vos propres tests
                  </div>
                </div>
                <div className="div-block-65home3">
                  <img
                    src="https://assets-global.website-files.com/652665cf77040f9e7ef0a916/65d22e0bf7d3a9b675c035ad_check-solid(1).svg"
                    loading="lazy"
                    width={20}
                    alt=""
                  />
                  <div className="text-block-94home2">
                    {" "}
                    Accès à la base de données
                    <br />
                    d'exercices
                  </div>
                </div>
                <div className="div-block-65home4">
                  <img
                    src="https://assets-global.website-files.com/652665cf77040f9e7ef0a916/65d22e0bf7d3a9b675c035ad_check-solid(1).svg"
                    loading="lazy"
                    width={20}
                    alt=""
                  />
                  <div className="text-block-94home3">
                    Envoyer les tests à vos candidats
                  </div>
                </div>
              </div>
              <div className="div-block-65home">
                <img
                  src="https://assets-global.website-files.com/652665cf77040f9e7ef0a916/65d22e0bf7d3a9b675c035ad_check-solid(1).svg"
                  loading="lazy"
                  width={20}
                  alt=""
                />
                <div className="text-block-94home4">
                  Compte rendu détaillé des
                  <br />
                  performances du candidat
                </div>
              </div>
              <div className="div-block-66home">
                <button onClick={handleBuy} className="button-18 w-button">
                  Acheter maintenant
                </button>
              </div>
            </div>
          </div>
        </div>
        <div id="faq" className="section">
          <Faq />
        </div>
        <div className="section">
          <div className="containerhome3">
            <div className="footer-wrap">
              <a href="#" className="webflow-link w-inline-block">
                <div className="paragraph-tiny">Calinfra ©️ 2024 </div>
              </a>
              <span className="footer-space"></span> {/* Spacer */}
              <a
                href="/cgu"
                className="webflow-link w-inline-block"
                style={{ marginLeft: "20px" }}
              >
                <div className="paragraph-tiny">Conditions d'utilisation</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
